import cx from 'classnames'
import { NavGroup, NavItem, NavItems } from './types'
import NavMobile from 'components/MarketingSite/Header/NavMobile'
import NavDesktop from 'components/MarketingSite/Header/NavDesktop'
import React, { useMemo } from 'react'
import RealmLogo, {
  RealmLogoSmall,
  RealmLogoPride,
  RealmLogoSmallPride,
} from 'components/MarketingSite/Header/RealmLogo'
import { signUpPathWithPublicProperty } from 'utils/publicProperty'
import RealmBusiness from 'svgs/realm-business'

import styles from './styles.module.scss'

function includeCampaignId(href: string, campaignId?: string): string {
  if (!campaignId) return href
  if (!window || !URLSearchParams) return href

  const parts = href.split('?')
  const searchParams = new URLSearchParams(parts[1] || '')
  searchParams.append('mc', campaignId)

  return `${parts[0]}?${searchParams.toString()}`
}

export const calculateNavItems = (
  taxAssessor?: AttomTaxAssessor,
  showDownloadApp?: string,
  hideHowItWorksButton?: boolean,
  hideResourcesButton?: boolean,
  hideAboutButton?: boolean,
  hideDashboardButton?: boolean,
  hideSignInButton?: boolean,
  hideSignupButton?: boolean,
  campaignId?: string
): NavItems => {
  return {
    ...(hideHowItWorksButton
      ? {}
      : {
          HowItWorks: {
            title: 'How it works',
            type: 'group',
            items: [
              { title: 'What is Realm?', href: '/how-it-works/what-is-realm', type: 'item' },
              { title: 'Renovate with Realm', href: '/how-it-works/renovate-with-realm', type: 'item' },
              { title: 'Join our vendor network', href: '/vendor-signup', type: 'item' },
            ],
          },
        }),
    ...(hideResourcesButton
      ? {}
      : {
          Resources: {
            title: 'Resources',
            href: '/resources/library',
            type: 'item',
          },
        }),
    ...(hideAboutButton
      ? {}
      : {
          About: {
            title: 'About',
            type: 'group',
            items: [
              { title: 'Our Story', href: '/about-us', type: 'item' },
              { title: 'Careers', href: '/about-us/careers', type: 'item' },
            ],
          },
        }),
    ...(hideDashboardButton
      ? {}
      : {
          Dashboard: { title: 'Dashboard', href: '/dashboard', type: 'item', hideOn: 'logged-out' },
        }),
    ...(hideSignInButton
      ? {}
      : {
          SignIn: {
            title: 'Sign in',
            type: 'group',
            hideOn: 'logged-in',
            items: [
              { title: 'As Homeowner', href: '/users/sign_in', type: 'item' },
              { title: 'As Vendor', href: '/vendors/sign_in', type: 'item' },
            ],
          },
        }),
    ...(showDownloadApp
      ? {
          DownloadApp: {
            title: 'Download the app',
            href: showDownloadApp,
            type: 'item',
            target: '_blank',
          },
        }
      : {}),
    ...(hideSignupButton
      ? {}
      : {
          GetStarted: {
            title: 'Get started',
            href: includeCampaignId(
              taxAssessor ? signUpPathWithPublicProperty(taxAssessor) : '/users/sign_up',
              campaignId
            ),
            type: 'item',
            hideOn: 'logged-in',
          },
        }),
  }
}

interface HeaderProps {
  navItems?: NavItems
  business?: boolean
  activeNavItem?: NavItem | NavGroup
  children?: React.ReactNode
  className?: string
  taxAssessor?: AttomTaxAssessor
  showDownloadApp?: string
  hideSignupButton?: boolean
  hideHowItWorksButton?: boolean
  hideResourcesButton?: boolean
  hideAboutButton?: boolean
  hideDashboardButton?: boolean
  hideSignInButton?: boolean
  linkLogo?: boolean
  logoClassName?: string
  campaignId?: string
}
const Header = React.forwardRef<HTMLElement, HeaderProps>(
  (
    {
      navItems,
      business,
      activeNavItem,
      children,
      className,
      taxAssessor,
      showDownloadApp,
      hideHowItWorksButton,
      hideResourcesButton,
      hideAboutButton,
      hideDashboardButton,
      hideSignInButton,
      hideSignupButton,
      linkLogo = true,
      logoClassName = 'tw-text-moss',
      campaignId,
    },
    ref
  ) => {
    navItems = useMemo(
      () =>
        navItems ||
        calculateNavItems(
          taxAssessor,
          showDownloadApp,
          hideHowItWorksButton,
          hideResourcesButton,
          hideAboutButton,
          hideDashboardButton,
          hideSignInButton,
          hideSignupButton,
          campaignId
        ),
      [
        navItems,
        taxAssessor,
        showDownloadApp,
        hideHowItWorksButton,
        hideResourcesButton,
        hideAboutButton,
        hideDashboardButton,
        hideSignInButton,
        hideSignupButton,
        campaignId,
      ]
    )

    const logo = useMemo(() => {
      return (
        <>
          {business ? (
            <RealmBusiness className="tw-w-44 lg:tw-w-72" />
          ) : process.env.PRIDE_MODE == 'true' ? (
            <>
              <RealmLogoPride className={cx(styles.logo, logoClassName)} />
              <RealmLogoSmallPride className={cx(styles.logoMobile, logoClassName)} />
            </>
          ) : (
            <>
              <RealmLogo className={cx(styles.logo, logoClassName)} />
              <RealmLogoSmall className={cx(styles.logoMobile, logoClassName)} />
            </>
          )}
        </>
      )
    }, [business, logoClassName])

    return (
      <header ref={ref} className={cx(styles.header, className)}>
        {linkLogo ? <a href="/">{logo}</a> : logo}
        {children != null ? children : <div />}
        <NavDesktop activeNavItem={activeNavItem} navItems={navItems} />
        <NavMobile navItems={navItems}>{children}</NavMobile>
      </header>
    )
  }
)
Header.displayName = 'Header'

export default Header
